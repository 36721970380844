import React, { useState, useContext } from 'react'
import { GlobalContext } from '../../context/GlobalState'
import { Box } from '../Box'
import { Button } from '../Buttons'
import { ZipInput } from '../Inputs/ZipInput'
import styles from './checker.module.scss'

export const Checker = () => {

    const time = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    const now = new Date(time)
    const curHour = now.getHours();

    const { errors, setErrors, zipCode, zipData, fetchZipData, isRiskArea, setRiskArea, showResult, setShowResult, options } = useContext(GlobalContext)

    const [loadingToday, setLoadingToday] = useState(false)
    const [loadingNextDay, setLoadingNextDay] = useState(false)

    const errorMessages = {
        zipcode: 'Bitte geben Sie eine gültige Postleitzahl ein!',
        today: 'Dieser Anreisecheck steht Ihnen ab 0:00 Uhr wieder zur Verfügung!',
        nextDay: 'Der Anreisecheck für den kommenden Tag steht Ihnen ab 11:00 Uhr zur Verfügung!'
    }


    const checkZipCode = async (version) => {
        setShowResult(false)

        const forToday = version === 'today'
        const forNextDay = version === 'nextDay'

        forToday ? setLoadingToday(true) : setLoadingNextDay(true)
        await fetchZipData();
        forToday ? setLoadingToday(false) : setLoadingNextDay(false)

        const zipCodes = forToday ? zipData.zip_codes.old : zipData.zip_codes.new

        let errors = []

        if (curHour >= 20 && forToday) {
            errors.push(errorMessages.today)
        }

        if (curHour < 11 && forNextDay) {
            errors.push(errorMessages.nextDay)
        }

        if (zipCode.length !== 5) {
            errors.push(errorMessages.zipcode)
        }

        setErrors(errors)

        if (errors.length > 0) {
            return;
        }

        if (zipCodes.includes(zipCode)) {
            setRiskArea(true)
        } else {
            setRiskArea(false)
        }

        setShowResult(true)
    }

    const replaceParams = (inputString) => {
        const newInputString = inputString
            .replace('{{PLZ}}', zipCode)
            .replace('{{Datum}}', zipData.last_update)
            .replace(/(?:\r\n|\r|\n)/g, '<br>');

        return newInputString
    }

    return (
        <div className={styles.checker}>
            <Box>Dieses Widget ist derzeit deaktiviert</Box>
            <span style={{ height: '25px' }}></span>
            <ZipInput disabled={true} placeholder='z.B. 10115' />
            <div className={styles.buttons} style={{ justifyContent: options.buttonPosition }}>
                <Button disabled={true} text={loadingToday ? 'Wird gecheckt ...' : 'Anreise heute'} onClick={() => checkZipCode('today')} />
                <Button disabled={true} text={loadingNextDay ? 'Wird gecheckt ...' : 'Anreise morgen'} onClick={() => checkZipCode('nextDay')} />
            </div>
            {showResult ?
                isRiskArea ?
                    <Box html={replaceParams(zipData.area_text_risky)} type="fail" />
                    :
                    <Box html={replaceParams(zipData.area_text_ok)} type="success" />
                : ''}
            {errors.length > 0 ?
                errors.map((error, i) =>
                    <Box key={i}>
                        {error}
                    </Box>
                )
                : ''}
        </div>
    )
}